import Colors from "@/assets/scss/_colors.module.scss";
import moment from "@/date";
export const getStatusTagColor = (status: string) => {
  switch (status) {
    case "unprocessed":
      return Colors.yellow;
    case "succeeded":
      return Colors.green;
    case "failed":
      return Colors.red;
    case "inactive":
      return Colors.grey;
    default:
      return "";
  }
};

export const calculateExecutionDates = ({
  next_execution_date,
  frequency,
  end_date,
  anchor_day,
}: {
  next_execution_date?: string;
  frequency?: number | null;
  end_date?: string;
  anchor_day?: number | null;
}): string[] => {
  const dates = [];
  if (next_execution_date && frequency) {
    for (let i = 0; i < 3; i++) {
      const nextDate = moment(next_execution_date).add(frequency * i, "days");
      const outOfDateRange = end_date && nextDate.isAfter(end_date);
      if (!outOfDateRange) {
        dates.push(nextDate.format("YYYY-MM-DD"));
      }
    }
  } else if (next_execution_date && anchor_day) {
    for (let i = 0; i < 3; i++) {
      let month =
        moment(next_execution_date).date() > anchor_day
          ? moment(next_execution_date).month() + 1
          : moment(next_execution_date).month();
      let year = moment(next_execution_date).year();
      if (month + i > 11) {
        month -= 12;
        year += 1;
      }
      const lastDayOfMonth = moment([year, month + i, 1])
        .endOf("month")
        .date();
      const nextDate = moment([year, month + i, Math.min(anchor_day, lastDayOfMonth)]);
      const outOfDateRange = end_date && nextDate.isAfter(end_date);
      if (!outOfDateRange) {
        dates.push(nextDate.format("YYYY-MM-DD"));
      }
    }
  }
  return dates;
};
