<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Projects</h3>
      </a-col>
      <a-col>
        Sorting:
        <a class="ml-1" @click="sortAllRoles('custom')">custom</a>
        <a-divider type="vertical" />
        <a @click="sortAllRoles('chronological')">chronological</a>
      </a-col>
      <a-col>
        Select:
        <a class="ml-1" @click="setRoles([])">none</a>
        <a-divider type="vertical" />
        <a
          @click="
            setRoles(cvData.user.roles.map((role) => (role.description ? role.id : undefined)))
          "
          >all</a
        >
      </a-col>
    </a-row>

    <em v-if="cvData.user.roles.length === 0" class="mb-4 d-block">No project roles</em>
    <a-checkbox-group
      v-else
      v-model="roles"
      class="d-block mb-4"
      @change="(roles) => setRoles(roles)"
    >
      <draggable v-model="allRoles" handle=".handle" @end="() => setRoles(roles)">
        <a-row v-for="role in allRoles" :key="role.id" class="mb-2" type="flex" align="middle">
          <a-col :span="2" class="d-flex align-items-center">
            <a-checkbox v-if="role.description" :value="role.id" />
            <a-tooltip
              :title="roles.includes(role.id) ? 'Feature' : 'Feature (select the project first)'"
              class="ml-2"
            >
              <a-icon
                v-if="role.description"
                :type="'star'"
                :theme="role.featured ? 'filled' : 'outlined'"
                :style="{
                  fontSize: '1.2em',
                  cursor: roles.includes(role.id) ? 'pointer' : 'not-allowed',
                  color: role.featured ? '#1890ff' : 'inherit',
                }"
                @click="roles.includes(role.id) && toggleFeaturedRole(role.id)"
              />
            </a-tooltip>
          </a-col>
          <a-col :span="22">
            <a-card :class="[role.description ? '' : 'conditional-transparency']" type="inner">
              <a-row type="flex">
                <a-col :span="6">
                  <a-row type="flex">
                    <a-col :span="18">
                      <a-tooltip
                        v-if="!role.custom_client_alias"
                        @click="toggleEditClientAlias(role)"
                      >
                        <template #title> Click to add custom client alias </template>
                        <h4>
                          {{
                            role.custom_client_alias ||
                            (role.hide_client_name || role.client === null
                              ? role.client_alias
                              : role.client)
                          }}
                        </h4>
                      </a-tooltip>
                      <SkTextarea v-else v-model="role.custom_client_alias" />
                    </a-col>
                    <a-col></a-col>
                  </a-row>

                  <a-tooltip v-if="!role.custom_role_title" @click="toggleEditRoleTitle(role)">
                    <template #title> Click to add custom role title </template>
                    <p class="mb-2">
                      {{ role.title }}
                    </p>
                  </a-tooltip>
                  <SkTextarea v-else v-model="role.custom_role_title" />

                  <p class="mt-2 mr-2">
                    {{ formatDate(role.start_date) }} - {{ formatDate(role.end_date) }}
                  </p>
                  <a v-if="role.client" type="link" @click="toggleHideClientName(role)">
                    {{ role.hide_client_name ? "Show name" : "Show alias" }}
                  </a>
                  <a
                    v-if="role.custom_client_alias"
                    type="link"
                    class="mb-2"
                    @click="toggleEditClientAlias(role)"
                  >
                    Remove custom alias
                  </a>
                  <a
                    v-if="role.custom_role_title"
                    type="link"
                    class="mb-2"
                    @click="toggleEditRoleTitle(role)"
                  >
                    Remove custom title
                  </a>
                </a-col>

                <a-col :span="16" class="description-col">
                  <p>
                    {{ role.description ? role.description : "Missing role description..." }}
                  </p>
                  <span
                    v-for="skill in role.skills"
                    :key="skill.id"
                    class="cv-badge cv-badge-rounded cv-badge-blue mt-2"
                  >
                    {{ skill.title }}
                  </span>
                </a-col>

                <a-col :span="2" class="handle-col">
                  <a-icon type="menu" class="handle" />
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </draggable>
    </a-checkbox-group>

    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Work Experiences</h3>
      </a-col>
      <a-col>
        Sorting:
        <a class="ml-1" @click="sortAllWorkExperiences('custom')">custom</a>
        <a-divider type="vertical" />
        <a @click="sortAllWorkExperiences('chronological')">chronological</a>
      </a-col>
      <a-col>
        Select:
        <a @click="setWorkExperiences([])">none</a>
        <a-divider type="vertical" />
        <a
          @click="
            setWorkExperiences(
              cvData.user.work_experiences.map((work) => (work.description ? work.id : undefined))
            )
          "
          >all</a
        >
      </a-col>
    </a-row>

    <em v-if="cvData.user.work_experiences.length === 0">No work experiences</em>
    <a-checkbox-group
      v-else
      v-model="workExperiences"
      class="d-block"
      @change="(workExperiences) => setWorkExperiences(workExperiences)"
    >
      <draggable
        v-model="allWorkExperiences"
        handle=".handle"
        @end="() => setWorkExperiences(workExperiences)"
      >
        <a-row
          v-for="workExperience in allWorkExperiences"
          :key="workExperience.id"
          class="mb-2"
          type="flex"
          align="middle"
        >
          <a-col :span="2" class="d-flex align-items-center">
            <a-checkbox v-if="workExperience.description" :value="workExperience.id" />
            <a-tooltip
              :title="
                workExperiences.includes(workExperience.id)
                  ? 'Feature'
                  : 'Feature (select the experience first)'
              "
              class="ml-2"
            >
              <a-icon
                v-if="workExperience.description"
                :type="'star'"
                :theme="workExperience.featured ? 'filled' : 'outlined'"
                :style="{
                  fontSize: '1.2em',
                  cursor: workExperiences.includes(workExperience.id) ? 'pointer' : 'not-allowed',
                  color: workExperience.featured ? '#1890ff' : 'inherit',
                }"
                @click="
                  workExperiences.includes(workExperience.id) &&
                  toggleFeaturedWorkExperience(workExperience.id)
                "
              />
            </a-tooltip>
          </a-col>
          <a-col :span="22">
            <a-card
              :class="[workExperience.description ? '' : 'conditional-transparency']"
              type="inner"
            >
              <a-row type="flex">
                <a-col :span="6">
                  <a-row type="flex">
                    <a-col :span="18">
                      <h4>{{ workExperience.company_name }}</h4>
                    </a-col>
                    <a-col></a-col>
                  </a-row>

                  <a-tooltip
                    v-if="!workExperience?.custom_role_title"
                    @click="toggleEditWorkExperienceTitle(workExperience)"
                  >
                    <template #title> Click to add custom role title </template>
                    <p class="mb-2">
                      {{ workExperience.title }}
                    </p>
                  </a-tooltip>
                  <SkTextarea v-else v-model="workExperience.custom_role_title" />

                  <p class="mt-2 mr-2">
                    {{ formatDate(workExperience.start_date) }} -
                    {{ formatDate(workExperience.end_date) }}
                  </p>

                  <a
                    v-if="workExperience.custom_role_title"
                    type="link"
                    class="mb-2"
                    @click="toggleEditWorkExperienceTitle(workExperience)"
                  >
                    Remove custom title
                  </a>
                </a-col>

                <a-col :span="16" class="description-col">
                  <p>
                    {{
                      workExperience.description
                        ? workExperience.description
                        : "Missing work experience description..."
                    }}
                  </p>
                  <a
                    v-if="workExperience.url_link"
                    :href="`${workExperience.url_link}`"
                    target="_blank"
                    class="d-flex mb-1"
                  >
                    {{ workExperience.url_link }}
                  </a>
                  <span
                    v-for="skill in workExperience.skills"
                    :key="skill.id"
                    class="cv-badge cv-badge-rounded cv-badge-blue mt-2"
                  >
                    {{ skill.title }}
                  </span>
                </a-col>

                <a-col :span="2" class="handle-col">
                  <a-icon type="menu" class="handle" />
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </draggable>
    </a-checkbox-group>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, toRefs } from "vue";
import draggable from "vuedraggable";
import { RoleInterface } from "@/modules/roles/types";
import {
  CvInterface,
  CvDataInterface,
  MinimalRoleCvInterface,
  RoleCvInterface,
  WorkExperienceCvInterface,
  MinimalWorkExperienceCvInterface,
} from "../types";
import moment from "@/date";
import { emitter } from "@/mitt";
import SkTextarea from "@/common/SkTextarea.vue";
import { WorkExperienceInterface } from "@/modules/users/types";
import { sortByCustomSortOrder } from "@/modules/cv/_utils/sorting";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Data Properties
const { cv, cvData } = toRefs(props);
const roles = ref<number[]>(cv.value.roles.map((role: MinimalRoleCvInterface) => role.role_id));
const allRoles = ref<Array<RoleCvInterface>>([]);
const workExperiences = ref<number[]>(
  cv.value.work_experiences.map(
    (experience: MinimalWorkExperienceCvInterface) => experience.work_experience_id
  )
);
const allWorkExperiences = ref<Array<WorkExperienceCvInterface>>([]);

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (e) => validate(e as string));

  // Combine all available roles with cv-specific metadata, specifically hide_client_name and custom_role_title
  props.cvData.user.roles.forEach((role: RoleInterface) => {
    const selected = props.cv.roles.find(
      (currentRole: MinimalRoleCvInterface) => role.id === currentRole.role_id
    );
    const newRole: RoleCvInterface = {
      ...role,
      hide_client_name: selected ? selected.hide_client_name : false,
      custom_role_title: selected ? selected.custom_role_title : undefined,
      custom_client_alias: selected ? selected.custom_client_alias : null,
      featured: selected ? selected.featured : false,
    };
    allRoles.value.push(newRole);
  });

  // When opening the menu, let roles shown on the cv be in cv order above the roles not shown on the cv
  sortAllRoles("custom");

  // Combine all available work experiences with cv-specific metadata, specifically custom_role_title
  props.cvData?.user.work_experiences.forEach((exp: WorkExperienceInterface) => {
    const selected = props.cv.work_experiences.find(
      (currentExp: MinimalWorkExperienceCvInterface) => exp.id === currentExp.work_experience_id
    );
    const newExp: WorkExperienceCvInterface = {
      ...exp,
      custom_role_title: selected ? selected.custom_role_title : undefined,
      featured: selected ? selected.featured : false,
    };
    allWorkExperiences.value.push(newExp);
  });

  // When opening the menu, let work experiences shown on the cv be in cv order above the experiences not shown on the cv
  sortAllWorkExperiences("custom");
});

onUnmounted(() => {
  emitter.off(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));
});

// Component methods
const validate = (nextStep: string) => {
  // Nothing to validate here
  emits("cv-change", "roles", transformRoles(roles.value));
  emits("cv-change", "work_experiences", transformWorkExperiences(workExperiences.value));
  emits("go-to-step", nextStep);
};

const setRoles = (r: number[]) => {
  roles.value = r;
  roles.value.sort((a, b) => {
    const aIndex = allRoles.value.findIndex((r) => r.id === a);
    const bIndex = allRoles.value.findIndex((r) => r.id === b);
    return aIndex - bIndex;
  });
  allRoles.value.forEach((role: RoleCvInterface) => {
    if (!roles.value.includes(role.id)) {
      role.featured = false;
    }
  });

  emits("cv-change", "roles", transformRoles(roles.value));
};

const toggleFeaturedRole = (roleId: number) => {
  const roleIndex = allRoles.value.findIndex((role: RoleCvInterface) => role.id === roleId);
  if (roleIndex !== -1) {
    allRoles.value[roleIndex].featured = !allRoles.value[roleIndex].featured;
    emits("cv-change", "roles", transformRoles(roles.value));
  }
};

const toggleFeaturedWorkExperience = (workExperienceId: number) => {
  const workExperienceIndex = allWorkExperiences.value.findIndex(
    (workExperience: WorkExperienceInterface) => workExperience.id === workExperienceId
  );

  if (workExperienceIndex !== -1) {
    allWorkExperiences.value[workExperienceIndex].featured =
      !allWorkExperiences.value[workExperienceIndex].featured;
    emits("cv-change", "work_experiences", transformWorkExperiences(workExperiences.value));
  }
};

const toggleHideClientName = (role: RoleCvInterface) => {
  const role_index = allRoles.value.findIndex((r) => r.id === role.id);
  allRoles.value[role_index].hide_client_name = !allRoles.value[role_index].hide_client_name;
  emits("cv-change", "roles", transformRoles(roles.value));
};

const toggleEditRoleTitle = (role: RoleCvInterface) => {
  const role_index = allRoles.value.findIndex((r) => r.id === role.id);
  allRoles.value[role_index].custom_role_title = allRoles.value[role_index].custom_role_title
    ? undefined
    : allRoles.value[role_index].title;
  emits("cv-change", "roles", transformRoles(roles.value));
};

const toggleEditClientAlias = (role: RoleCvInterface) => {
  const role_index = allRoles.value.findIndex((r) => r.id === role.id);
  if (allRoles.value[role_index].custom_client_alias) {
    allRoles.value[role_index].custom_client_alias = null;
  } else {
    allRoles.value[role_index].custom_client_alias =
      role.hide_client_name || role.client === null ? role.client_alias : role.client;
  }
  emits("cv-change", "roles", transformRoles([role.id]));
};

const transformRoles = (roles: number[]): Array<MinimalRoleCvInterface> => {
  const newRoles: Array<MinimalRoleCvInterface> = [];
  roles.forEach((role) => {
    const r = allRoles.value.find((r) => r.id === role);
    if (!r) return;
    newRoles.push({
      role_id: role,
      hide_client_name: r.hide_client_name,
      custom_role_title: r.custom_role_title || undefined,
      custom_client_alias: r.custom_client_alias || null,
      featured: r.featured,
    });
  });
  return newRoles;
};

const toggleEditWorkExperienceTitle = (exp: WorkExperienceCvInterface) => {
  const expIndex = allWorkExperiences.value.findIndex((w) => w.id === exp.id);
  allWorkExperiences.value[expIndex].custom_role_title = allWorkExperiences.value[expIndex]
    .custom_role_title
    ? undefined
    : allWorkExperiences.value[expIndex].title;
  emits("cv-change", "work_experiences", transformWorkExperiences(workExperiences.value));
};

const setWorkExperiences = (w: number[]) => {
  workExperiences.value = w;
  workExperiences.value.sort((a, b) => {
    const aIndex = allWorkExperiences.value.findIndex((work) => work.id === a);
    const bIndex = allWorkExperiences.value.findIndex((work) => work.id === b);
    return aIndex - bIndex;
  });
  allWorkExperiences.value.forEach((exp: RoleCvInterface) => {
    if (!workExperiences.value.includes(exp.id)) {
      exp.featured = false;
    }
  });
  emits("cv-change", "work_experiences", transformWorkExperiences(workExperiences.value));
};

const transformWorkExperiences = (exps: number[]): Array<MinimalWorkExperienceCvInterface> => {
  const newExperiences: Array<MinimalWorkExperienceCvInterface> = [];
  exps.forEach((expIndex) => {
    const r = allWorkExperiences.value.find((w) => w.id === expIndex);
    if (!r) return;
    newExperiences.push({
      work_experience_id: expIndex,
      custom_role_title: r.custom_role_title,
      featured: r.featured,
    });
  });
  return newExperiences;
};

const formatDate = (date: string) => {
  return moment(date).format("MMMM YYYY");
};

const sortAllRoles = (how: "chronological" | "custom") => {
  if (how === "chronological") {
    allRoles.value.sort((a, b) => Date.parse(b.start_date) - Date.parse(a.start_date));
  }
  if (how === "custom") {
    allRoles.value = sortByCustomSortOrder(
      allRoles.value,
      props.cv.roles,
      (ar) => ar.id,
      (cr) => cr.role_id
    );
  }
  setRoles(roles.value);
};

const sortAllWorkExperiences = (how: "chronological" | "custom") => {
  if (how === "chronological") {
    allWorkExperiences.value.sort((a, b) => Date.parse(b.start_date) - Date.parse(a.start_date));
  }
  if (how === "custom") {
    allWorkExperiences.value = sortByCustomSortOrder(
      allWorkExperiences.value,
      props.cv.work_experiences,
      (awe) => awe.id,
      (cwe) => cwe.work_experience_id
    );
  }
  setWorkExperiences(workExperiences.value);
};
</script>

<style lang="scss" scoped>
$default-border-radius: 4px;
$default-border: 1px solid #ebebeb;

@mixin shadow-border {
  border: $default-border;
  border-bottom-width: 1.5px;
}

.description-col {
  border-left: 1px lightGrey solid;
  padding-left: 0.9rem;
  word-wrap: break-word;
}

.handle-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.handle {
  padding: 0.5rem;
  cursor: move;
}

.conditional-transparency {
  opacity: 0.5;
}

.cv-badge {
  display: inline-block;
  background: white;
  padding: 0.5em 1.25em;
  border-radius: $default-border-radius;
  font-size: smaller;

  &:not(:last-of-type) {
    margin-right: 1em;
  }

  &-block {
    display: block;
    text-align: center;
  }

  &-raised {
    @include shadow-border;
  }

  &-rounded {
    border-radius: 100px;
  }

  &-blue {
    background: rgba(0, 100, 255, 0.1);
    border: 0;
  }
}
</style>
