<template>
  <div class="m-2">
    <div v-if="loadingReports" class="d-flex justify-content-center my-4">
      <a-icon type="loading" />
    </div>
    <div v-if="!loadingReports && !userReports.get(1)" class="d-flex justify-content-center my-4">
      <h2>No reports available.</h2>
    </div>
    <div>
      <div v-for="(r, index) in userReports.get(1)" :key="r.id">
        <div class="p-1 mt-2">
          <div class="flex-center-between">
            <div class="d-flex flex-column align-items-start">
              <div v-if="showUsers" class="d-flex align-items-center">
                <a-avatar :src="r.user.gravatar" class="mr-2" />
                <h3 class="mb-0 ml-2">
                  <router-link :to="{ name: 'user', params: { id: r.user.id } }">
                    {{ r.user.name }}
                  </router-link>
                </h3>
              </div>

              <div v-if="showRoles || r.type !== ReportType.TIME" class="d-flex align-items-center">
                <a-icon
                  :component="getReportIonIconName(r.type)"
                  :class="r.type == ReportType.VACATION ? 'text-yellow' : ''"
                />
                <!-- Role names under Latest Reports card in Dashboard -->
                <h3 class="mb-0 ml-2">{{ getReportTypeText(r) }}</h3>
                <div v-if="r.type === ReportType.TIME">
                  <h3 class="mb-0 ml-2">
                    (<router-link
                      :to="{ name: 'project', params: { projectId: r.project.id } }"
                      class="blackLink"
                      >{{ r.project.name }}</router-link
                    >)
                  </h3>
                </div>
              </div>
            </div>

            <div style="padding-top: 2px">
              <span style="padding-bottom: 2px">{{ formatReportDate(r.start_date) }}</span>
              <span v-if="r.end_date">- {{ r.end_date }}</span>
            </div>
          </div>
          <div v-if="r.type === ReportType.TIME">
            <span class="flex-center-between">
              <p style="font-size: 0.9rem" class="mb-3">
                <!-- Client names under Latest Reports card in Dashboard -->
                <router-link
                  :to="{ name: 'client', params: { id: r.project.client.id } }"
                  class="clientText"
                  >{{ r.project.client.name }}</router-link
                >
              </p>
              <p v-if="showExpandComment && r.comment">
                <a-icon
                  v-show="withComment !== r.id"
                  type="plus"
                  class="action-default"
                  @click="handleShowComment(r)"
                />
                <a-icon
                  v-show="isMinus && withComment === r.id"
                  type="minus"
                  class="action-default"
                  @click="handleHideComment()"
                />
              </p>
            </span>
            <p v-if="showExpandComment && r.comment && withComment === r.id">
              <em>{{ r.comment }}</em>
            </p>
            <p v-if="showComments && r.comment">
              <em>{{ r.comment }}</em>
            </p>
          </div>
          <div v-if="r.type === ReportType.DETACHEDTIME" class="mb-3">
            <em>{{ r.comment }}</em>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <a-icon
                v-if="
                  r.type === ReportType.TIME ||
                  r.type === ReportType.SICK ||
                  r.type === ReportType.DETACHEDTIME
                "
                :class="r.locked ? 'text-yellow' : 'text-red'"
                :type="r.locked ? 'lock' : 'unlock'"
              />
              <div v-if="r.type === ReportType.TIME || r.type === ReportType.DETACHEDTIME">
                <a-divider type="vertical" />
                <span>{{ r.amount }} hours</span>
                <a-divider type="vertical" />
                <span>{{ toMoney(r.hourly_rate) }}/hr</span>
              </div>
              <div v-if="displayReportDuration(r)">
                <div>{{ reportDuration(r) }}</div>
              </div>
            </div>

            <div>
              <a-tooltip
                v-if="r.type === ReportType.TIME || r.type === ReportType.DETACHEDTIME"
                placement="top"
              >
                <template #title>Copy report to form</template>
                <a-icon
                  v-if="r.project"
                  class="action-default"
                  type="copy"
                  @click="$emit('copy-report-to-form', r)"
                />
              </a-tooltip>
              <a-divider v-if="r.editable" type="vertical" />
              <a-icon
                v-if="r.editable"
                class="action-default"
                type="edit"
                @click="showWarningUpdateLockedReport(r)"
              />
              <a-divider v-if="r.deletable" type="vertical" />
              <a-icon
                v-if="r.deletable"
                class="action-danger"
                type="delete"
                @click="showDeleteReportConfirm(r)"
              />
            </div>
          </div>
          <a-divider v-if="index !== userReports.length - 1" class="my-2" />
        </div>
      </div>
      <div v-if="showAllReportsLink" class="d-flex justify-content-center mt-4">
        <router-link v-if="!loadingReports" :to="{ name: 'reports' }"
          >Go to All Reports</router-link
        >
      </div>
      <div v-if="showMyReportsLink" class="d-flex justify-content-center mt-4">
        <router-link
          v-if="user && !loadingReports"
          :to="{ name: 'reports', query: { 'filters[user_id][]': user.id } }"
          >Go to My Reports</router-link
        >
      </div>
    </div>

    <!-- EDIT USER-REPORT MODAL -->
    <edit-user-report-modal
      :report="report"
      :user="user"
      :visible="editUserReportModalVisible"
      :user-roles="userRoles"
      :user-vacation-and-absence-reports="userVacationAndAbsenceReports"
      :user-parental-reports="userParentalReports"
      :cost-centers="costCenters"
      @update-report="(updatedReport) => $emit('update-report', updatedReport)"
      @close="editUserReportModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { computeNbBusinessDaysV2 } from "../_utils/public-holidays";
import { ProfileInterface } from "@/modules/authentication/types";
import EditUserReportModal from "./EditUserReportModal.vue";
import { useReportsStore } from "@/modules/reports/_store";
import { RoleInterface } from "../../roles/types";
import { getMonthQueryKey } from "@/utils";
import useMixin from "@/useMixin";
import moment from "@/date";
import {
  PublicHolidayInterface,
  ReportInterface,
  ReportPayloadInterface,
  ReportType,
} from "../types";
import SunnySvg from "ionicons/dist/ionicons/svg/sunny.svg";
import TimeSvg from "ionicons/dist/ionicons/svg/time.svg";
import MedkitSvg from "ionicons/dist/ionicons/svg/medkit.svg";
import HomeSvg from "ionicons/dist/ionicons/svg/home.svg";
import CashSvg from "ionicons/dist/ionicons/svg/cash.svg";
import RocketSvg from "ionicons/dist/ionicons/svg/rocket.svg";

// Props
const props = defineProps({
  userVacationAndAbsenceReports: { type: Array as () => Array<ReportInterface>, default: () => [] },
  userParentalReports: { type: Array as () => Array<ReportInterface>, default: () => [] },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  userRoles: { type: Array as () => Array<RoleInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  publicHolidays: { type: Map<String, Array<PublicHolidayInterface>>, default: () => [] },
  userReports: { type: Map<any, any>, default: () => [] },
  showAllReportsLink: { type: Boolean, default: false },
  showExpandComment: { type: Boolean, default: false },
  showMyReportsLink: { type: Boolean, default: false },
  loadingReports: { type: Boolean, default: false },
  showComments: { type: Boolean, default: false },
  showRoles: { type: Boolean, default: false },
  showUsers: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["destroy-report"]);

// Mixins
const { toMoney } = useMixin();

// Pinia
const reportsStore = useReportsStore();

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;

// Data Properties
const editUserReportModalVisible = ref<boolean>(false);
const report = ref<ReportInterface | undefined>(undefined);
const isMinus = ref<boolean>(false);
const withComment = ref<string | number>("");
const allPublicHolidays = ref<Array<PublicHolidayInterface>>([]);

// Watchers
watch(
  () => props.userReports,
  () => {
    const publicHolidayMonthsToQuery: Set<string> = new Set();

    props.userReports.forEach((reports: Array<ReportPayloadInterface>, _key: string) => {
      reports
        .filter((report: ReportPayloadInterface) => report.start_date && report.end_date)
        .forEach((report: ReportPayloadInterface) => {
          const startMonth = getMonthQueryKey(report.start_date!);
          const endMonth = getMonthQueryKey(report.end_date!);
          publicHolidayMonthsToQuery.add(startMonth);
          publicHolidayMonthsToQuery.add(endMonth);
        });
    });

    publicHolidayMonthsToQuery.forEach((monthKey: string) =>
      reportsStore.getPublicHolidays(monthKey)
    );
  }
);

watch(
  () => props.publicHolidays,
  () => {
    allPublicHolidays.value = [];
    props.publicHolidays.forEach((phs: Array<PublicHolidayInterface>, _key: string) => {
      phs.forEach((ph: PublicHolidayInterface) => allPublicHolidays.value.push(ph));
    });
  }
);

// Component Methods
const handleUpdateReport = (r: ReportInterface): void => {
  report.value = r;
  editUserReportModalVisible.value = true;
};

const handleShowComment = (report: ReportInterface): void => {
  if (report.id) withComment.value = report.id;
  isMinus.value = true;
};

const handleHideComment = (): void => {
  isMinus.value = false;
  withComment.value = "";
};

const showDeleteReportConfirm = (report: ReportInterface): void => {
  const mod = $confirm?.({
    title: `Are you sure you want to delete the ${
      report.type ? report.type.replace("-", " ") : ""
    } of ${report.start_date}${
      report.type === ReportType.TIME ? ` for '${report.role ? report.role.title : ""}'` : "?"
    }`,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      emits("destroy-report", report.id);
      mod?.destroy();
    },
  });
};

const showWarningUpdateLockedReport = (report: ReportInterface): void => {
  if (report.locked) {
    const mod = $confirm?.({
      title: "Are you sure you want modify a locked report?",
      okText: "Continue",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        mod?.destroy();
        handleUpdateReport(report);
      },
    });
  } else {
    handleUpdateReport(report);
  }
};

const reportDuration = (report: ReportInterface): string => {
  if (!report.start_date || !report.end_date) {
    return "";
  }

  const nbDays = moment.duration(
    moment(report.end_date).diff(moment(report.start_date), "days") + 1,
    "days"
  );

  const nbBusinessDays = computeNbBusinessDaysV2(
    report.start_date,
    report.end_date,
    allPublicHolidays.value
  );

  return `${nbDays.humanize()}${
    nbDays.days() !== nbBusinessDays ? ` [${nbBusinessDays} business days]` : ""
  }`;
};

const displayReportDuration = (report: ReportInterface): boolean => {
  return (
    report.type === ReportType.VACATION ||
    report.type === ReportType.PARENTAL ||
    report.type === ReportType.ABSENCE
  );
};

const getReportTypeText = (report: ReportInterface): string => {
  switch (report.type) {
    case ReportType.TIME:
      return report.role ? report.role.title : "";
    case ReportType.VACATION:
      return "Vacation leave";
    case ReportType.SICK:
      return "Sick leave";
    case ReportType.PARENTAL:
      return "Parental leave";
    case ReportType.EXPENSE:
      return report.title ? report.title : "";
    case ReportType.ABSENCE:
      return "Leave of absence";
    case ReportType.DETACHEDTIME:
      return "Time report (without project)";
    default:
      return ReportType.TIME;
  }
};

const getReportIonIconName = (reportType: string): string => {
  switch (reportType) {
    case ReportType.TIME || ReportType.DETACHEDTIME:
      return TimeSvg;
    case ReportType.VACATION:
      return SunnySvg;
    case ReportType.SICK:
      return MedkitSvg;
    case ReportType.PARENTAL:
      return HomeSvg;
    case ReportType.EXPENSE:
      return CashSvg;
    case ReportType.ABSENCE:
      return RocketSvg;
    default:
      return TimeSvg;
  }
};

const getTodaysDateFormatted = (): string => {
  return new Date().toJSON().slice(0, 10);
};

const getYesterdaysDateFormatted = (): string => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return d.toJSON().slice(0, 10);
};

const formatReportDate = (date: string): string => {
  if (date === getTodaysDateFormatted()) return "Today";
  else if (date === getYesterdaysDateFormatted()) return "Yesterday";
  return date;
};
</script>
