<template>
  <a-layout-header :class="['navbar-wrapper', navbarColour]">
    <div class="container d-flex justify-content-between">
      <!-- COLLAPSE BUTTON (LEFT SIDE) -->
      <div class="d-flex">
        <a-icon
          :type="sidebarCollapsed ? 'menu-unfold' : 'menu-fold'"
          class="navbar-icon collapse"
          @click="toggleSidebarCollapse()"
        />
        <div
          v-if="baseAcl.isInternal"
          class="d-flex ml-3 ml-lg-0 flex-column justify-content-center"
        >
          <input
            :value="searchQuery"
            :maxlength="QUERY_MAX_LENGTH"
            class="ais-input"
            placeholder="Search"
            @click="() => enterSearchMode()"
            @input="({ target }) => handleSearch(target.value)"
          />
          <div v-show="inSearchMode" class="search-backdrop" @click="() => resetSearchMode()" />
        </div>
      </div>

      <!-- RIGHT SIDE CONTENT -->
      <div class="navbar-right">
        <!-- GRAVATAR, USER NAME AND HOME BUTTON -->
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <div class="d-flex align-items-center">
            <a-avatar :src="profile?.gravatar" size="large" />
            <h3 class="profile-name navbar-name">{{ profile?.name }}</h3>
          </div>

          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link :to="{ name: 'user', params: { id: profile?.id } }">
                  Profile
                </router-link>
              </a-menu-item>

              <a-menu-item>
                <router-link :to="{ name: 'skp' }">SKP</router-link>
              </a-menu-item>

              <a-menu-item v-if="baseAcl.isInternal">
                <router-link :to="{ name: 'cv', params: { id: profile?.cv.id } }">
                  New CV
                </router-link>
              </a-menu-item>

              <a-menu-divider />

              <a-menu-item>
                <a @click="() => signOut()">Logout</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>

    <div v-if="baseAcl.isInternal" class="container">
      <!-- EMPTY SEARCH BAR PLACEHOLDER -->
      <div v-show="searchQuery === '' && inSearchMode" style="position: relative">
        <div class="search-results py-2 ant-dropdown-link">
          <a-icon type="search" class="mr-2" />
          <em>Search for Clients, Users and Projects...</em>
        </div>
      </div>

      <div v-show="searchQuery !== '' && inSearchMode" style="position: relative">
        <div class="search-results">
          <!-- CLIENTS SEARCH RESULTS -->
          <div>
            <h3 class="mb-0">
              <strong>Clients</strong>
            </h3>
            <div
              v-for="(client, index) in searchQueryResults.clients"
              v-show="searchQueryResults.clients.length > 0"
              :key="client.id"
              :class="'px-3 py-0 ' + (index % 2 === 0 ? 'bg-white' : 'bg-grey_lighter')"
            >
              <router-link :to="{ name: 'client', params: { id: client.id } }">
                {{ client.name }}
              </router-link>
            </div>
            <span v-show="searchQueryResults.clients && searchQueryResults.clients.length === 0">
              No clients found for
              <em>{{ searchQuery }}</em>
            </span>
          </div>

          <a-divider class="my-2" />

          <!-- USERS SEARCH RESULTS -->
          <div>
            <h3 class="mb-0">
              <strong>Users</strong>
            </h3>
            <div
              v-for="(user, index) in searchQueryResults.users"
              v-show="searchQueryResults.users.length > 0"
              :key="user.id"
              :class="'px-3 py-0 ' + (index % 2 === 0 ? 'bg-white' : 'bg-grey_lighter')"
            >
              <span class="d-flex align-items-center">
                <a-avatar :src="user.gravatar" size="small" class="mr-2" />
                <router-link :to="{ name: 'user', params: { id: user.id } }" class="mr-2">
                  {{ user.name }}
                </router-link>
                <a-icon v-show="user.on_vacation" :component="SunnySvg" class="text-yellow" />
              </span>
            </div>
            <span v-show="searchQueryResults.users && searchQueryResults.users.length === 0">
              No users found for
              <em>{{ searchQuery }}</em>
            </span>
          </div>

          <a-divider class="my-2" />

          <!-- PROJECTS SEARCH RESULTS -->
          <div>
            <h3 class="mb-0">
              <strong>Projects</strong>
            </h3>
            <div
              v-for="(project, index) in searchQueryResults.projects"
              v-show="searchQueryResults.projects.length > 0"
              :key="project.id"
              :class="'px-3 py-0 ' + (index % 2 === 0 ? 'bg-white' : 'bg-grey_lighter')"
            >
              <div class="d-flex align-items-center">
                <router-link
                  :to="{ name: 'project', params: { projectId: project.id } }"
                  class="mr-4"
                >
                  {{ project.name }}
                </router-link>
                <a-tag v-if="project.status === 'closed'" :color="Colors.orange">
                  {{ project.status }}
                </a-tag>
                <a-tag v-if="project.status === 'active'" :color="Colors.green">
                  {{ project.status }}
                </a-tag>
              </div>
            </div>
            <span v-show="searchQueryResults.projects && searchQueryResults.projects.length === 0">
              No projects found for
              <em>{{ searchQuery }}</em>
            </span>
          </div>
        </div>
      </div>
    </div>
  </a-layout-header>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import Colors from "@/assets/scss/_colors.module.scss";
import { useCommonStore } from "@/modules/common/_store";
import SunnySvg from "ionicons/dist/ionicons/svg/sunny.svg";

// Pinia
const authenticationStore = useAuthenticationStore();
const commonStore = useCommonStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
const searchQueryResults = computed(() => commonStore.searchQueryResults);
const sidebarCollapsed = computed<boolean>(() => commonStore.sidebarCollapsed);
const inSearchMode = computed<boolean>(() => commonStore.inSearchMode);
const searchQuery = computed<string>(() => commonStore.searchQuery);
const toggleSidebarCollapse = () => commonStore.toggleSidebarCollapse();
const resetSearchQuery = () => commonStore.resetSearchQuery();
const enterSearchMode = () => commonStore.enterSearchMode();
const resetSearchMode = () => commonStore.resetSearchMode();

// Data Properties
const QUERY_MAX_LENGTH = ref<number>(100);

// Computed Properties
const navbarColour = import.meta.env.VITE_ENV === "production" ? "bg-white" : "bg-red";

// Component Method
const handleSearch = (query: string) => {
  if (query !== "") {
    commonStore.getSearchQueryResults(query);
  } else {
    resetSearchQuery();
  }
};

const signOut = () => {
  authenticationStore.logout().then(() => (window.location.href = "/"));
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);

  .navbar-icon {
    font-size: 18px;
    line-height: 64px;
  }

  .collapse {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .search-backdrop {
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #3d4852;
    opacity: 0.3;
    z-index: 4;
  }

  .navbar-right {
    display: flex;
    align-items: center;

    .profile-name {
      margin-bottom: 0;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .navbar-name {
    display: none;

    @media (min-width: 768px) {
      display: initial;
    }
  }
}
</style>
