<template>
  <div class="card">
    <empty-resource-table v-if="!hasRecurringJobs && !loadingTable" resource="recurring jobs" />
    <div v-else>
      <a-table
        :data-source="recurringJobs"
        :loading="loadingTable"
        :columns="recurringJobsColumns"
        :pagination="false"
        :expand-row-by-click="true"
        :row-key="(recurringJob) => recurringJob.id"
        class="mb-2"
      >
        <template #status="status, record">
          <a-tooltip>
            <template #title>
              <p>{{ record.status_message }}</p>
            </template>
            <a-tag :color="getStatusTagColor(status)">{{ status.toUpperCase() }}</a-tag>
          </a-tooltip>
        </template>

        <template #dates="record">
          <span>
            {{ record.next_execution_date }}
            <a-tooltip class="ml-2">
              <template #title>
                {{ record.start_date }} -
                {{ record.end_date ? record.end_date : "&infin;" }}
              </template>
              <a-icon type="clock-circle" class="action-default" />
            </a-tooltip>
          </span>
        </template>

        <template #type="type">
          <span class="text-capitalize">
            {{ type.split("-").join(" ") }}
          </span>
        </template>

        <template #frequency="record">
          <span>{{ getFrequencyText(record) }}</span>
        </template>

        <template #actions="record">
          <a-icon
            type="edit"
            class="action-default"
            @click.stop="handleEditRecurringJobModalVisible(record)"
          />
          <a-divider type="vertical" />
          <a-popconfirm
            title="Are you want to delete this recurring job?"
            @confirm="$emit('destroy-recurring-job', record.id)"
          >
            <a-icon type="delete" class="action-danger" />
          </a-popconfirm>
        </template>

        <template #expandedRowRender="record">
          <a-row type="flex">
            <a-col v-if="record.description" :span="12">
              <h4>Description:</h4>
              <p class="pre-wrap">{{ record.description }}</p>
            </a-col>
            <a-col v-else :span="12">Missing description</a-col>
            <a-col v-if="record.note" :span="12">
              <h4>Notes:</h4>
              <p class="pre-wrap">{{ record.note }}</p>
            </a-col>
            <a-col v-else :span="12">Missing notes</a-col>
          </a-row>
        </template>
      </a-table>

      <a-row type="flex" justify="end">
        <a-pagination
          :current="currentPage"
          :total="recurringJobsPagination?.total"
          :show-size-changer="true"
          :page-size="pageSize"
          :page-size-options="['10', '25', '50', '100']"
          size="small"
          @change="handleGetRecurringJobsPage"
          @showSizeChange="
            (pageNumber, newPageSize) => handleGetRecurringJobsPage(pageNumber, newPageSize)
          "
        />
      </a-row>
    </div>

    <edit-recurring-job-modal
      :visible="editRecurringJobModalVisible"
      :recurring-job="editRecurringJob"
      @update-recurring-job="handleUpdateRecurringJob"
      @close="editRecurringJobModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import EmptyResourceTable from "../../common/_components/EmptyResourceTable.vue";
import { RecurringJobInterface, RecurringJobPayloadInterface } from "../types";
import EditRecurringJobModal from "../_components/EditRecurringJobModal.vue";
import { recurringJobsColumns } from "../_utils/tables";
import { getStatusTagColor } from "../_utils/utils";

// Props
const props = defineProps({
  recurringJobsPagination: { type: Object, default: undefined },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  recurringJobs: { type: Array as () => Array<RecurringJobInterface>, default: () => [] },
  pageSize: { type: Number, default: 25 },
});

// Emits
const emits = defineEmits(["get-recurring-jobs-page", "update-recurring-job"]);

// Data Properties
const editRecurringJobModalVisible = ref<boolean>(false);
const editRecurringJob = ref<RecurringJobInterface | undefined>(undefined);

// Computed Properties
const hasRecurringJobs = computed(() => {
  return props.recurringJobs.length > 0;
});

// Component Methods
const handleGetRecurringJobsPage = (page: any, pageSize: number): void => {
  emits("get-recurring-jobs-page", { page, pageSize });
};

const handleEditRecurringJobModalVisible = (recurringJob: RecurringJobInterface): void => {
  editRecurringJob.value = recurringJob;
  editRecurringJobModalVisible.value = true;
};

const handleUpdateRecurringJob = (updatedRecurringJob: RecurringJobPayloadInterface): void => {
  editRecurringJobModalVisible.value = false;
  emits("update-recurring-job", updatedRecurringJob);
};
const getFrequencyText = (record: RecurringJobInterface): string => {
  if (record.frequency) {
    return `Every ${record.frequency} days`;
  } else {
    return `Day ${record.anchor_day} of every month`;
  }
};
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}
</style>
