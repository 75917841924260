<template>
  <a-modal
    :visible="visible"
    title="Create recurring job"
    ok-text="Create"
    @cancel="$emit('close')"
    @ok="handleStoreRecurringJob"
  >
    <a-form data-vv-scope="recurring-job">
      <a-row>
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('recurring-job.title') ? 'error' : ''"
            :help="$validator.errors.first('recurring-job.title')"
            :colon="false"
            label="Title"
          >
            <a-input
              v-model="recurringJob.title"
              v-validate="'required'"
              data-vv-name="title"
              placeholder="Title"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('recurring-job.start_date') ? 'error' : ''"
            :help="$validator.errors.first('recurring-job.start_date')"
            :colon="false"
            label="Starting on"
          >
            <a-date-picker
              v-validate="'required'"
              :value="parseDate(recurringJob.start_date)"
              :disabled-date="disabledDateForStartDate"
              data-vv-name="start_date"
              data-vv-as="start date"
              allow-clear
              placeholder="Start date"
              style="width: 100%"
              @change="(date) => onDatesChange('start_date', date)"
            />
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-form-item :colon="false" label="Invalid after (optional)">
            <a-date-picker
              :value="parseDate(recurringJob.end_date)"
              :disabled-date="disabledDateForEndDate"
              allow-clear
              placeholder="Invalid after"
              style="width: 100%"
              @change="(date) => onDatesChange('end_date', date)"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item>
            <a-checkbox :checked="isMonthly" @change="toggleMonthlyOccurrence()"
              >Recurring monthly</a-checkbox
            >
          </a-form-item>
          <a-form-item
            v-if="isMonthly"
            :validate-status="$validator.errors.first('recurring-job.anchor_day') ? 'error' : ''"
            :help="$validator.errors.first('recurring-job.anchor_day')"
            :colon="false"
            label="Day of month"
          >
            <a-input
              v-model="recurringJob.anchor_day"
              v-validate="'required|integer|min_value:1|max_value:31'"
              data-vv-name="anchor_day"
              placeholder="Day"
            />
          </a-form-item>
          <a-form-item
            v-else
            :validate-status="$validator.errors.first('recurring-job.frequency') ? 'error' : ''"
            :help="$validator.errors.first('recurring-job.frequency')"
            :colon="false"
            label="Days between occurrences"
          >
            <a-input
              v-model="recurringJob.frequency"
              v-validate="'required|integer|min_value:0|max_value:365'"
              data-vv-name="frequency"
              placeholder="Days"
            />
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-timeline mode="right" class="mb-0">
            <a-timeline-item
              v-for="(date, i) in executionDates"
              :key="i"
              :class="i === executionDates.length - 1 ? 'small-timeline-item' : ''"
            >
              <span class="mr-1">{{ `${ordinalNumber(i + 1)}` }} time</span> -
              <span class="ml-1">{{ date }}</span>
            </a-timeline-item>
          </a-timeline>
        </a-col>
      </a-row>

      <a-row>
        <a-form-item :colon="false" label="Description">
          <a-textarea
            v-model="recurringJob.description"
            :auto-size="{ minRows: 3 }"
            placeholder="Description"
            allow-clear
          />
        </a-form-item>
      </a-row>

      <a-row>
        <a-form-item :colon="false" label="Notes">
          <a-textarea
            v-model="recurringJob.note"
            :auto-size="{ minRows: 3 }"
            placeholder="Notes"
            allow-clear
          />
        </a-form-item>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, computed, watch, toRef, getCurrentInstance } from "vue";
import { RecurringJobPayloadInterface } from "../types";
import moment from "@/date";
import useFilters from "@/useFilters";
import { calculateExecutionDates } from "../_utils/utils";

// Props
const props = defineProps({
  entity: { type: Object, default: undefined },
  type: { type: String, default: undefined },
  defaultNote: { type: String, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-recurring-job"]);

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const isMonthly = ref<boolean>(false);
const recurringJob = ref<RecurringJobPayloadInterface>({
  title: undefined,
  start_date: undefined,
  end_date: undefined,
  anchor_day: undefined,
  frequency: undefined,
  entity_id: undefined,
  note: undefined,
  description: undefined,
  type: undefined,
});

// Computed Properties
const executionDates = computed((): string[] => {
  return calculateExecutionDates({
    next_execution_date: recurringJob.value.start_date,
    frequency: recurringJob.value.frequency,
    end_date: recurringJob.value.end_date,
    anchor_day: recurringJob.value.anchor_day,
  });
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    recurringJob.value.entity_id = props.entity.id;
    recurringJob.value.type = props.type;
    recurringJob.value.note = props.defaultNote;
  }
});

// Component Methods
const handleStoreRecurringJob = (): void => {
  $validator?.validateAll("recurring-job").then((result) => {
    if (result) {
      emits("store-recurring-job", recurringJob.value);
      isMonthly.value = false;
      recurringJob.value = {
        anchor_day: undefined,
        title: undefined,
        start_date: undefined,
        end_date: undefined,
        frequency: undefined,
        entity_id: undefined,
        note: undefined,
        description: undefined,
        type: undefined,
      };
    }
  });
};

const disabledDateForStartDate = (current: any): any => {
  return (
    current &&
    recurringJob.value.end_date &&
    current > moment(recurringJob.value.end_date).endOf("day")
  );
};

const disabledDateForEndDate = (current: any): any => {
  return (
    current &&
    recurringJob.value.start_date &&
    current < moment(recurringJob.value.start_date).startOf("day")
  );
};

const onDatesChange = (attribute: string, date: any): void => {
  recurringJob.value[attribute] = date ? date.format("YYYY-MM-DD") : undefined;
};

const ordinalNumber = (n: any): any => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]); // Magic
};
const toggleMonthlyOccurrence = (): void => {
  if (isMonthly.value) {
    recurringJob.value.frequency = recurringJob.value.anchor_day;
    recurringJob.value.anchor_day = undefined;
  } else {
    recurringJob.value.anchor_day = recurringJob.value.frequency;
    recurringJob.value.frequency = undefined;
  }
  isMonthly.value = !isMonthly.value;
};
</script>

<style scoped>
.small-timeline-item {
  padding-bottom: 0;
  height: 15px;
}
</style>
