<template>
  <a-modal
    :visible="visible"
    title="Edit recurring job"
    ok-text="Update"
    @cancel="$emit('close')"
    @ok="handleUpdateRecurringJob"
  >
    <a-form data-vv-scope="recurring-job">
      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('recurring-job.title') ? 'error' : ''"
            :help="$validator.errors.first('recurring-job.title')"
            :colon="false"
            label="Title"
          >
            <a-input
              v-model="updatedRecurringJob.title"
              v-validate="'required'"
              data-vv-name="title"
              placeholder="Title"
            />
          </a-form-item>
          <SkMoney
            v-model="updatedRecurringJob.amount_cents"
            v-validate="'decimal'"
            :error="$validator.errors.first('recurring-job.amount_cents')"
            :currency="updatedRecurringJob.amount_currency ?? 'SEK'"
            data-vv-name="amount_cents"
            data-vv-as="amount"
            placeholder="Update amount"
            label="Amount"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 25 }"
            @set-currency="(v) => (updatedRecurringJob.amount_currency = v)"
          />
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item
            :validate-status="
              $validator.errors.first('recurring-job.next_execution_date') ? 'error' : ''
            "
            :help="$validator.errors.first('recurring-job.next_execution_date')"
            :colon="false"
            label="Next execution date"
          >
            <a-date-picker
              v-validate="'required'"
              :value="parseDate(updatedRecurringJob.next_execution_date)"
              :disabled-date="disabledDateForNextExecutionDate"
              data-vv-name="next_execution_date"
              data-vv-as="next execution date"
              allow-clear
              placeholder="Next execution date"
              style="width: 100%"
              @change="(date) => onDatesChange('next_execution_date', date)"
            />
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-form-item :colon="false" label="Invalid after (optional)">
            <a-date-picker
              :value="parseDate(updatedRecurringJob.end_date)"
              :disabled-date="disabledDateForEndDate"
              allow-clear
              placeholder="Invalid after"
              style="width: 100%"
              @change="(date) => onDatesChange('end_date', date)"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item>
            <a-checkbox :checked="isMonthly" @change="setMonthlyOccurance()"
              >Recurring monthly</a-checkbox
            >
          </a-form-item>
          <a-form-item
            v-if="isMonthly"
            :validate-status="$validator.errors.first('recurring-job.anchor_day') ? 'error' : ''"
            :help="$validator.errors.first('recurring-job.anchor_day')"
            :colon="false"
            label="Day of month"
          >
            <a-input
              v-model="updatedRecurringJob.anchor_day"
              v-validate="'required|integer|min_value:1|max_value:31'"
              data-vv-name="anchor_day"
              placeholder="Day"
            />
          </a-form-item>
          <a-form-item
            v-else
            :validate-status="$validator.errors.first('recurring-job.frequency') ? 'error' : ''"
            :help="$validator.errors.first('recurring-job.frequency')"
            :colon="false"
            label="Days between occurrences"
          >
            <a-input
              v-model="updatedRecurringJob.frequency"
              v-validate="'required|integer|min_value:0|max_value:365'"
              data-vv-name="frequency"
              placeholder="Days"
            />
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-timeline mode="right" class="mb-0">
            <a-timeline-item
              v-for="(date, i) in executionDates"
              :key="date"
              :class="i === executionDates.length - 1 ? 'small-timeline-item' : ''"
            >
              <span class="mr-1">{{ `${ordinalNumber(i + 1)}` }} time</span> -
              <span class="ml-1">{{ date }}</span>
            </a-timeline-item>
          </a-timeline>
        </a-col>
      </a-row>

      <a-row>
        <a-form-item :colon="false" label="Description">
          <a-textarea
            v-model="updatedRecurringJob.description"
            :auto-size="{ minRows: 3 }"
            placeholder="Description"
            allow-clear
          />
        </a-form-item>
      </a-row>

      <a-row>
        <a-form-item :colon="false" label="Notes">
          <a-textarea
            v-model="updatedRecurringJob.note"
            :auto-size="{ minRows: 3 }"
            placeholder="Notes"
            allow-clear
          />
        </a-form-item>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, computed, watch, toRef, getCurrentInstance } from "vue";
import { RecurringJobInterface, RecurringJobPayloadInterface } from "../types";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";
import { calculateExecutionDates } from "../_utils/utils";
import SkMoney from "@/common/SkMoney.vue";

// Props
const props = defineProps({
  recurringJob: { type: Object as () => RecurringJobInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-recurring-job"]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instances = getCurrentInstance();
const $validator = instances?.proxy.$validator;

// Data properties
const isMonthly = ref<boolean>(false);
const updatedRecurringJob = ref<RecurringJobPayloadInterface>({
  id: undefined,
  title: undefined,
  next_execution_date: undefined,
  anchor_day: undefined,
  end_date: undefined,
  frequency: undefined,
  entity_id: undefined,
  description: undefined,
  note: undefined,
  type: undefined,
  amount_cents: undefined,
  amount_currency: undefined,
});

// Computed Properties
const executionDates = computed((): string[] => {
  return calculateExecutionDates(updatedRecurringJob.value);
});
// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    updatedRecurringJob.value = setObject(updatedRecurringJob.value, props.recurringJob);
    if (props.recurringJob.amount) {
      updatedRecurringJob.value.amount_cents = props.recurringJob.amount.value
        ? props.recurringJob.amount.value / 100
        : undefined;
      updatedRecurringJob.value.amount_currency = props.recurringJob.amount.currency_code;
    }
    isMonthly.value = !!updatedRecurringJob.value.anchor_day;
  }
});

// Component Methods
const handleUpdateRecurringJob = (): void => {
  $validator?.validateAll("recurring-job").then((result) => {
    if (result) {
      emits("update-recurring-job", {
        ...updatedRecurringJob.value,
        amount_cents: updatedRecurringJob.value.amount_cents
          ? toMoneyCents(updatedRecurringJob.value.amount_cents)
          : undefined,
        amount_currency: updatedRecurringJob.value.amount_cents
          ? updatedRecurringJob.value.amount_currency
          : undefined,
      });
      updatedRecurringJob.value = {
        id: undefined,
        title: undefined,
        next_execution_date: undefined,
        anchor_day: undefined,
        end_date: undefined,
        frequency: undefined,
        entity_id: undefined,
        description: undefined,
        note: undefined,
        type: undefined,
        amount_cents: undefined,
        amount_currency: undefined,
      };
    }
  });
};

const disabledDateForNextExecutionDate = (current: any): boolean => {
  return Boolean(
    current &&
      updatedRecurringJob.value.end_date &&
      current > moment(updatedRecurringJob.value.end_date).endOf("day")
  );
};

const disabledDateForEndDate = (current: any): boolean => {
  return (
    current &&
    updatedRecurringJob.value.next_execution_date &&
    current < moment(updatedRecurringJob.value.next_execution_date).startOf("day")
  );
};

const onDatesChange = (attribute: string, date: any): void => {
  updatedRecurringJob.value[attribute] = date ? date.format("YYYY-MM-DD") : undefined;
};

const ordinalNumber = (n: number): string => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]); // Magic
};

const setMonthlyOccurance = (): any => {
  if (isMonthly.value) {
    updatedRecurringJob.value.frequency = updatedRecurringJob.value.anchor_day;
    updatedRecurringJob.value.anchor_day = null;
  } else {
    updatedRecurringJob.value.anchor_day = updatedRecurringJob.value.frequency;
    updatedRecurringJob.value.frequency = null;
  }
  isMonthly.value = !isMonthly.value;
};
</script>

<style scoped>
.small-timeline-item {
  padding-bottom: 0;
  height: 15px;
}
</style>
