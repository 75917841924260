<template>
  <a-layout-content class="my-4">
    <div class="container">
      <div
        v-if="user !== null && currentDate.getMonth() === 11 && daysSaved === null && canSaveDays"
        style="cursor: pointer"
        @click="vacationDaysModalVisible = true"
      >
        <a-alert
          class="mb-3"
          message="Time to report saved vacation days"
          type="warning"
          show-icon
        />
      </div>
      <div
        v-if="user !== null && !user?.onboarding_completed"
        style="cursor: pointer"
        @click="onboardingModalVisible = true"
      >
        <a-alert
          class="mb-3"
          message="Please finish the Skynet onboarding"
          type="warning"
          show-icon
        />
      </div>
      <router-link v-if="user !== null && !user?.onboarding_quiz_completed" :to="'/dashboard/quiz'">
        <a-alert
          class="mb-3"
          :message="`You still have onboarding quiz questions`"
          type="warning"
          show-icon
        />
      </router-link>
      <router-link
        v-if="pendingReceiptsCount > 0 && baseAcl.isFinancial"
        :to="'/dashboard/receipts?filters[receipt_status][]=pending'"
      >
        <a-alert
          class="mb-3"
          :message="`There ${
            pendingReceiptsCount > 1 ? 'are' : 'is'
          } ${pendingReceiptsCount} pending receipt${
            pendingReceiptsCount > 1 ? 's' : ''
          } waiting to be reviewed`"
          type="warning"
          show-icon
        />
      </router-link>
      <router-link
        v-if="pendingInvoicesCount > 0 && baseAcl.isFinancial"
        :to="'/dashboard/invoices?filters[invoice_type]=RequestedInvoice&filters[invoice_status]=requested'"
      >
        <a-alert
          class="mb-3"
          :message="`There ${
            pendingInvoicesCount > 1 ? 'are' : 'is'
          } ${pendingInvoicesCount} pending invoice${
            pendingInvoicesCount > 1 ? 's' : ''
          } waiting to be reviewed`"
          type="warning"
          show-icon
        />
      </router-link>
      <router-link
        v-if="undecidedRoleExtensionsCount > 0"
        :to="`/dashboard/roles?filters[user_id][]=${user?.id}&filters[extension_statuses][]=waiting`"
      >
        <a-alert
          class="mb-3"
          :message="`There ${
            undecidedRoleExtensionsCount > 1 ? 'are' : 'is'
          } ${undecidedRoleExtensionsCount} role extension${
            undecidedRoleExtensionsCount > 1 ? 's' : ''
          } waiting for your decision`"
          type="warning"
          show-icon
        />
      </router-link>
      <router-link
        v-if="baseAcl.isCr && decidedRoleExtensionsCount > 0"
        :to="`/dashboard/schedule?filters[extension_statuses][]=to_be_discussed&filters[extension_statuses][]=approved`"
      >
        <a-alert
          class="mb-3"
          :message="`There ${
            decidedRoleExtensionsCount > 1 ? 'are' : 'is'
          } ${decidedRoleExtensionsCount} role extension${
            decidedRoleExtensionsCount > 1 ? 's' : ''
          } waiting to be acted upon`"
          type="warning"
          show-icon
        />
      </router-link>
      <router-link
        v-if="user !== null && incompleteRolesCount > 0"
        :to="`/dashboard/roles?filters[user_id][]=${user?.id}&filters[role_status]=incomplete&filters[disable_cv]=false`"
      >
        <a-alert
          class="mb-3"
          :message="`You have roles missing description and/or skills`"
          type="warning"
          show-icon
        />
      </router-link>

      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Dashboard</h2>
        <a-button
          icon="lock"
          class="btn-warning"
          type="primary"
          @click="lockReportsModalVisible = true"
          >Lock Reports</a-button
        >
      </div>

      <!-- TABS -->
      <a-tabs :active-key="tab" class="overflow-visible" @change="handleTabChanged">
        <a-tab-pane key="reports" tab="My Reports">
          <my-reports :tab="tab" />
        </a-tab-pane>

        <a-tab-pane key="roles" tab="My Roles">
          <my-roles
            :tab="tab"
            :acl="baseAcl"
            :user-id="profile?.id"
            @reload-role-extension-banners="populateUserBanners"
          />
        </a-tab-pane>

        <a-tab-pane key="projects" tab="My Projects">
          <my-projects :tab="tab" :acl="baseAcl" :user-id="profile?.id" />
        </a-tab-pane>

        <a-tab-pane key="receipts" tab="My Receipts">
          <my-receipts :tab="tab" :acl="baseAcl" :user-id="profile?.id" />
        </a-tab-pane>

        <a-tab-pane v-if="baseAcl.isInternal" key="links" tab="Useful Links">
          <useful-links :tab="tab" />
        </a-tab-pane>
      </a-tabs>

      <!-- LOCK REPORTS MODAL -->
      <lock-reports-modal
        :user="user"
        :visible="lockReportsModalVisible"
        @lock-user-reports="(lockingDate) => handleLockUserReports(lockingDate)"
        @close="lockReportsModalVisible = false"
      />

      <!-- ONBOARDING MODAL -->
      <onboarding-modal
        :visible="user !== null && !user?.onboarding_completed && onboardingModalVisible"
        :user="user"
        :acl="baseAcl"
        @reload-user="handleReloadUser"
        @close="onboardingModalVisible = false"
      />

      <!-- SAVE VACTION DAYS MODAL -->
      <VacationDaysModal
        :visible="user !== null && vacationDaysModalVisible"
        :user="user"
        :days-left="daysLeft"
        :max-days-to-save="maxDaysToSave"
        @close="handleSave"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, watch, onMounted } from "vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { ProfileInterface, BaseAclInterface } from "../../authentication/types";
import UsefulLinks from "../../useful_links/_views/UsefulLinks.vue";
import LockReportsModal from "../_components/LockReportsModal.vue";
import { apiGetRoleExtensions, apiGetRoles } from "@/modules/roles/_utils/api";
import OnboardingModal from "../_components/OnboardingModal.vue";
import MyProjects from "../../projects/_views/MyProjects.vue";
import MyReceipts from "../../receipts/_views/MyReceipts.vue";
import MyReports from "../../reports/_views/MyReports.vue";
import { apiGetReceipts } from "../../receipts/_utils/api";
import { apiGetInvoices } from "../../invoices/_utils/api";
import MyRoles from "../../roles/_views/MyRoles.vue";
import { apiGetUser } from "../../users/_utils/api";
import { useReportsStore } from "@/modules/reports/_store";
import { emitter } from "@/mitt";
import VacationDaysModal from "../_components/VacationDaysModal.vue";
import { apiGetUserVacationYear } from "@/modules/users/_utils/api";
import { VacationInterface } from "@/modules/transactions/types";
import * as Sentry from "@sentry/browser";

// Pinia
const authenticationStore = useAuthenticationStore();
const reportsStore = useReportsStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy.$route;
const $router = instance?.proxy.$router;
const $message = instance?.proxy.$message;

// Data Properties
const user = ref<ProfileInterface | undefined>(undefined);
const lockReportsModalVisible = ref<boolean>(false);
const undecidedRoleExtensionsCount = ref<number>(0);
const onboardingModalVisible = ref<boolean>(false);
const decidedRoleExtensionsCount = ref<number>(0);
const pendingReceiptsCount = ref<number>(0);
const pendingInvoicesCount = ref<number>(0);
const tab = ref<string>("reports");
const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();
const vacationDaysModalVisible = ref<boolean>(false);
const daysSaved = ref<number>(null);
const daysLeft = ref<number>(null);
const vacationYear = ref<VacationInterface>(null);
const chosenDays = ref<number>(null);
const maxDaysToSave = ref<number>(null);
const canSaveDays = ref<boolean>(false);
const incompleteRolesCount = ref<number>(0);

// Life-cycle hooks
onMounted(() => {
  changeTab($route?.hash as string);
  user.value = profile.value;
  onboardingModalVisible.value = user.value !== undefined && !user.value?.onboarding_completed;
  if (baseAcl.value.isFinancial) {
    fetchFinancialNotifications();
  }

  populateUserBanners();

  if (currentMonth === 11) {
    apiGetUserVacationYear(user.value.id, currentYear)
      .then((res) => {
        vacationYear.value = res.data.data;
        const currentYearData = vacationYear.value;
        if (currentYearData) {
          const {
            vacation_days_paid,
            vacation_days_earned,
            vacation_days_reported,
            vacation_days_saved,
            saved_days_balance,
          } = currentYearData;
          const earnedOrPaid = Math.max(vacation_days_paid, vacation_days_earned);
          const maximumVacationDays = earnedOrPaid + saved_days_balance;
          daysSaved.value = vacation_days_saved;
          const reportedDays = vacation_days_reported ?? 0;
          daysLeft.value = maximumVacationDays - reportedDays;
          maxDaysToSave.value = Math.min(
            vacation_days_paid - vacation_days_reported,
            vacation_days_earned * 0.2
          );
          canSaveDays.value = maxDaysToSave?.value > 0 ? true : false;
        }
      })
      .catch((err: any) => {
        if (err.status != 404) Sentry.captureException(err);
      });
  }
});

// Watchers
watch(
  () => instance?.proxy.$route?.hash,
  (hash: any) => {
    changeTab(hash);
  }
);

// Component Methods
const changeTab = (hash: string) => {
  if (hash) {
    const t = hash.substring(1);
    if (tab.value !== t) {
      handleTabChanged(t);
    }
  }
};

const isTabValid = (tab: string) => {
  return ["reports", "roles", "projects", "receipts", "links"].includes(tab);
};

const handleTabChanged = (t: string) => {
  if (!isTabValid(t)) {
    t = "";
  }
  tab.value = t;
  $router?.replace(t ? `#${t}` : "");
};

const handleLockUserReports = (lockingDate: string) => {
  reportsStore
    .lockUserReports({ userId: profile.value?.id, lockingDate })
    .then(() => {
      apiGetUser(profile.value?.id as number).then((res: any) => (user.value = res.data.data));
      $message?.success("Reports locked successfully!", 3);

      emitter.emit("reload-all-reports");
      emitter.emit("reload-user");
    })
    .catch(() => $message?.error("Couldn't lock reports!", 3));
};

const fetchFinancialNotifications = () => {
  apiGetReceipts({ receipt_status: "pending" }).then(
    ({ data }) => (pendingReceiptsCount.value = data.data.length)
  );

  apiGetInvoices({
    invoice_status: "requested",
    invoice_type: "RequestedInvoice",
  }).then(({ data }) => (pendingInvoicesCount.value = data.data.length));
};

const populateUserBanners = () => {
  if (user.value) {
    apiGetRoleExtensions({
      role_user_id: user.value.id,
      extension_statuses: ["waiting"],
    }).then((res: any) => {
      undecidedRoleExtensionsCount.value = res.data.data.length;
    });

    apiGetRoleExtensions({
      extension_statuses: ["to_be_discussed", "approved"],
    }).then((res: any) => {
      decidedRoleExtensionsCount.value = res.data.data.length;
    });

    apiGetRoles({
      user_id: [user.value.id],
      role_status: "incomplete",
      disable_cv: false,
    }).then((res: any) => {
      incompleteRolesCount.value = res.data.meta.total;
    });
  }
};

const handleReloadUser = () => {
  apiGetUser(profile.value?.id as number).then((res: any) => (user.value = res.data.data));
};

const handleSave = (choice: ref<number>) => {
  if (choice) {
    chosenDays.value = choice.value;
    vacationDaysModalVisible.value = false;
    daysSaved.value = choice.value;
  } else {
    vacationDaysModalVisible.value = false;
  }
};
</script>

<style scoped>
.overflow-visible {
  overflow: visible !important;
}
</style>
